<template>
  <span
    class="material-symbols-rounded"
    translate="no"
    :style="{
      'font-variation-settings': `
      'FILL' ${props.fillIcon ? 1 : 0},
      'wght' 400,
      'GRAD' 0,
      'opsz' 24
    `,
    }"
  >
    {{ name }}
  </span>
</template>

<script setup>
const props = defineProps({
  name: { default: 'disabled_by_default', type: String },
  fillIcon: { default: false, type: Boolean },
})
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Material+Symbols+Rounded:opsz,wght,FILL,GRAD@24,400,0..1,0');
</style>
