import { RouteRecordRaw } from 'vue-router'

export default [
  {
    name: 'services',
    path: 'services',
    component: () => import('../../components/TabsView.vue'),
    redirect: { name: 'services-main' },
    props: {
      title: 'Servicios',
      routes: [],
    },
    meta: {
      title: 'Servicios',
    },
    children: [
      {
        meta: {
          title: 'Servicios',
        },
        name: 'services-main',
        path: '',
        component: () => import('../../pages/general-services/ServiceList.vue'),
      },
      {
        meta: {
          title: 'Editar Servicio',
        },
        name: 'service-detail',
        path: ':id',
        component: () => import('../../pages/general-services/CreateOrModify.vue'),
        //New
        props: (route) => ({
          isNew: false,
          elementId: route.params.id,
          key: route.params.id, // esto fuerza la recreación del componente
        }),
      },
      {
        meta: {
          title: 'Crear Servicio',
        },
        name: 'service-creation',
        path: 'creation',
        component: () => import('../../pages/general-services/CreateOrModify.vue'),
        //New
        props: {
          isNew: true,
          key: 'new', // clave única para la creación
        },
      },
    ],
  },
] as RouteRecordRaw[]
