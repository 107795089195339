import { RouteRecordRaw } from 'vue-router'

import { conglomeratesRoutes } from './realestate/conglomerates-routes'

const propertiesRoutes: RouteRecordRaw[] = [
  {
    name: 'realestate-property-detail',
    path: 'realestate/properties/:id',
    component: () => import('../../pages/realestate/properties/CreateOrModify.vue'),
    meta: {
      title: 'Propiedad',
    },
    props: (route) => ({
      isNew: false,
      elementId: route.params.id,
    }),
  },
  {
    name: 'realestate-new-property',
    path: 'realestate/properties/new',
    component: () => import('../../pages/realestate/properties/CreateOrModify.vue'),
    meta: {
      title: 'Nueva Propiedad',
    },
    props: {
      isNew: true,
    },
  },
]

const rentOffersRoutes: RouteRecordRaw[] = [
  {
    name: 'realestate-rentoffer-detail',
    path: 'realestate/rent-offers/:id',
    component: () => import('../../pages/realestate/rent-offers/CreateOrModify.vue'),
    meta: {
      title: 'Oferta de Renta',
    },
    props: (route) => ({
      isNew: false,
      elementId: route.params.id,
    }),
  },
  {
    name: 'realestate-new-rentoffer',
    path: 'realestate/rent-offers/new',
    component: () => import('../../pages/realestate/rent-offers/CreateOrModify.vue'),
    meta: {
      title: 'Nueva Oferta de Renta',
    },
    props: {
      isNew: true,
    },
  },
]

const saleOffersRoutes: RouteRecordRaw[] = [
  {
    name: 'realestate-saleoffer-detail',
    path: 'realestate/sale-offers/:id',
    component: () => import('../../pages/realestate/sale-offers/CreateOrModify.vue'),
    meta: {
      title: 'Oferta de Venta',
    },
    props: (route) => ({
      isNew: false,
      elementId: route.params.id,
    }),
  },
  {
    name: 'realestate-new-saleoffer',
    path: 'realestate/sale-offers/new',
    component: () => import('../../pages/realestate/sale-offers/CreateOrModify.vue'),
    meta: {
      title: 'Nueva Oferta de Venta',
    },
    props: {
      isNew: true,
    },
  },
]

const servicesRoutes: RouteRecordRaw[] = [
  {
    name: 'realestate-service-detail',
    path: 'realestate/services/:id',
    component: () => import('../../pages/realestate/services/CreateOrModify.vue'),
    meta: {
      title: 'Servicio',
    },
    props: (route) => ({
      isNew: false,
      elementId: route.params.id,
    }),
  },
  {
    name: 'realestate-new-service',
    path: 'realestate/services/new',
    component: () => import('../../pages/realestate/services/CreateOrModify.vue'),
    meta: {
      title: 'Nuevo Servicio',
    },
    props: {
      isNew: true,
    },
  },
]

const tagsRoutes: RouteRecordRaw[] = [
  {
    name: 'realestate-tag-detail',
    path: 'realestate/tags/:id',
    component: () => import('../../pages/realestate/tags/CreateOrModify.vue'),
    meta: {
      title: 'Tag',
    },
    props: (route) => ({
      isNew: false,
      elementId: route.params.id,
    }),
  },
  {
    name: 'realestate-new-tag',
    path: 'realestate/tags/new',
    component: () => import('../../pages/realestate/tags/CreateOrModify.vue'),
    meta: {
      title: 'Nuevo Tag',
    },
    props: {
      isNew: true,
    },
  },
]

const advertisersRoutes: RouteRecordRaw[] = [
  {
    name: 'realestate-advertiser-detail',
    path: 'realestate/advertiser/:id',
    component: () => import('../../pages/realestate/advertisers/CreateOrModify.vue'),
    meta: {
      title: 'Anuncio',
    },
    props: (route) => ({
      isNew: false,
      elementId: route.params.id,
    }),
  },
]
export default [
  {
    name: 'realestate',
    path: 'realestate',
    component: () => import('../../components/TabsView.vue'),
    redirect: { name: 'conglomerates' },
    props: {
      title: 'Realestate',
      routes: [
        { name: 'conglomerates', label: 'Conglomerados' },
        { name: 'realestate-properties', label: 'Propiedades' },
        { name: 'realestate-rent-offers', label: 'Ofertas de Renta' },
        { name: 'realestate-sale-offers', label: 'Ofertas de Venta' },
        { name: 'realestate-services', label: 'Servicios' },
        { name: 'realestate-tags', label: 'Tags' },
        { name: 'realestate-settings', label: 'Configuración' },
        { name: 'realestate-rent-agreements', label: 'Acuerdos de Renta' },
        { name: 'realestate-advertisers', label: 'Anuncios' },
      ],
    },
    children: [
      {
        name: 'realestate-properties',
        path: 'properties',
        component: () => import('../../pages/realestate/properties/PropertiesPage.vue'),
        meta: {
          title: 'Propiedades',
        },
      },
      {
        name: 'realestate-rent-offers',
        path: 'rent-offers',
        component: () => import('../../pages/realestate/rent-offers/RentOffersPage.vue'),
        meta: {
          title: 'Ofertas de Renta',
        },
      },
      {
        name: 'realestate-sale-offers',
        path: 'sale-offers',
        component: () => import('../../pages/realestate/sale-offers/SaleOffersPage.vue'),
        meta: {
          title: 'Ofertas de Venta',
        },
      },
      {
        name: 'realestate-services',
        path: 'services',
        component: () => import('../../pages/realestate/services/ServicesPage.vue'),
        meta: {
          title: 'Servicios',
        },
      },
      {
        name: 'realestate-tags',
        path: 'tags',
        component: () => import('../../pages/realestate/tags/TagsPage.vue'),
        meta: {
          title: 'Tags',
        },
      },
      {
        name: 'realestate-settings',
        path: 'settings',
        component: () => import('../../pages/realestate/settings/SettingsPage.vue'),
        meta: {
          title: 'Configuraciones',
        },
      },
      {
        name: 'realestate-rent-agreements',
        path: 'rent-agreements',
        component: () => import('../../pages/realestate/rent-agreement/RentAgreementPage.vue'),
        meta: {
          title: 'Acuerdos de Renta',
        },
      },
      {
        name: 'conglomerates',
        path: 'realestate/conglomerates',
        component: () => import('../../pages/realestate/conglomerates/conglomerates-list/Conglomerates-list.vue'),
        meta: {
          title: 'Conglomerados',
        },
      },
      {
        name: 'realestate-advertisers',
        path: 'realestate/advertisers',
        component: () => import('../../pages/realestate/advertisers/AdvertisersPage.vue'),
        meta: {
          title: 'Anuncios',
        },
      },
    ],
  },
  ...propertiesRoutes,
  ...rentOffersRoutes,
  ...saleOffersRoutes,
  ...servicesRoutes,
  ...tagsRoutes,
  ...conglomeratesRoutes,
  ...advertisersRoutes,
]
