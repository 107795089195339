import { RouteRecordRaw } from 'vue-router'

const merchantRoutes: RouteRecordRaw[] = [
  {
    name: 'payments-merchant-detail',
    path: 'payments/merchant/:id',
    component: () => import('../../pages/payments/merchant/CreateOrModify.vue'),
    meta: {
      title: 'Merchant Details',
    },
    props: (route) => ({
      isNew: false,
      elementId: route.params.id,
    }),
  },
]

const operationRoutes: RouteRecordRaw[] = [
  {
    name: 'payments-operation-detail',
    path: 'payments/operations/:id',
    component: () => import('../../pages/payments/operations/Details.vue'),
    meta: {
      title: 'Payment Details',
    },
    props: (route) => ({
      isNew: false,
      elementId: route.params.id,
    }),
  },
]
export default [
  {
    name: 'payments',
    path: 'payments',
    component: () => import('../../components/TabsView.vue'),
    redirect: { name: 'payments-operations' },
    props: {
      title: 'Pagos',
      routes: [
        { name: 'payments-operations', label: 'Operaciones' },
        { name: 'payments-balance-operations', label: 'Operaciones de Balance' },
        { name: 'payments-merchants', label: 'Merchants' },
        { name: 'payments-refunds', label: 'Reembolsos' },
      ],
    },
    children: [
      {
        name: 'payments-operations',
        path: 'operations',
        component: () => import('../../pages/payments/operations/PaymentsOperationsPage.vue'),
        meta: {
          title: 'Operaciones de Pago',
        },
      },
      {
        name: 'payments-balance-operations',
        path: 'balance-operations',
        component: () => import('../../pages/payments/operationsBalance/PaymentsOperationsBalancePage.vue'),
        meta: {
          title: 'Operaciones de Balance',
        },
      },
      {
        name: 'payments-merchants',
        path: 'merchants',
        component: () => import('../../pages/payments/merchant/PaymentsMerchantPage.vue'),
        meta: {
          title: 'Merchants',
        },
      },
      {
        name: 'payments-refunds',
        path: 'refunds',
        component: () => import('../../pages/payments/refund/PaymentsRefoundsPage.vue'),
        meta: {
          title: 'Reembolsos',
        },
      },
    ],
  },
  ...merchantRoutes,
  ...operationRoutes,
] as RouteRecordRaw[]
