import { RouteRecordRaw } from 'vue-router'

export const conglomeratesRoutes: RouteRecordRaw[] = [
  {
    name: 'realestate-new-conglomerate',
    path: 'realestate/conglomerates/create',
    component: () => import('../../../pages/realestate/conglomerates/conglomerate-creation/ConglomerateCreation.vue'),
    meta: {
      title: 'Nuevo Conglomerado',
    },
    props: () => ({
      isNew: true,
    }),
  },
  {
    name: 'realestate-conglomerate-detail',
    path: 'realestate/conglomerates/:id',
    component: () => import('../../../pages/realestate/conglomerates/conglomerate-creation/ConglomerateCreation.vue'),
    meta: {
      title: 'Editar Conglomerado',
    },
    props: (route) => ({
      isNew: false,
      elementId: route.params.id,
    }),
  },
]
