<template>
  <div class="w-full flex place-items-center gap-1 p-2 relative overflow-x-auto tabs-container">
    <TransitionGroup name="tabtransition" @beforeLeave="beforeLeave">
      <!--class="px-2 py-1 bg-[var(--va-background-secondary)] rounded-md inline-flex place-items-center shadow-lg font-bold cursor-pointer transition-all duration-500 whitespace-nowrap select-none"
 -->
      <div
        key="/dashboard"
        class="tab-item"
        :class="'/dashboard' === route.path ? ['selected-item'] : ['non-selected-item']"
        @click="router.push({ path: '/dashboard' })"
      >
        <span> Dashboard </span>
      </div>
      <!--class="block px-2 bg-[var(--va-background-secondary)] dark:black rounded-md inline-flex place-items-center shadow-lg font-bold h-full cursor-pointer transition-all duration-500 whitespace-nowrap select-none"
 -->
      <div
        v-for="(tab, index) in tabs"
        :key="tab.path"
        :to="{ path: tab.path }"
        class="tab-item"
        :class="tab.path === route.path ? ['selected-item'] : ['non-selected-item']"
        @click="router.push({ path: tab.path })"
      >
        <span>{{ tab.name }}</span>
        <CoolButton class="clean mini" after-icon="close" @click.stop="closeTab(index)" />
      </div>
    </TransitionGroup>
  </div>
</template>

<script lang="ts" setup>
import CoolButton from '../dara-components/CoolButton.vue'
import { useRoute, useRouter } from 'vue-router'
import { useGlobalStore } from '../../stores/global-store'
import { storeToRefs } from 'pinia'
// import { useColors } from 'vuestic-ui'

const route = useRoute()
const router = useRouter()
const { tabs } = storeToRefs(useGlobalStore())

// const { currentPresetName } = useColors()

const closeTab = (tabIndex: number) => {
  const [removedTab] = tabs.value.splice(tabIndex, 1)

  if (route.path === removedTab.path) {
    router.push({
      path: tabs.value.length === 0 ? '/dashboard' : tabs.value[tabs.value.length - 1].path,
    })
  }
}

const beforeLeave = (ele: Element): void => {
  const el = ele as HTMLElement
  const { marginLeft, marginTop, width, height } = window.getComputedStyle(el)

  el.style.left = `${el.offsetLeft - parseFloat(marginLeft)}px`
  el.style.top = `${el.offsetTop - parseFloat(marginTop)}px`
  el.style.width = width
  el.style.height = height
}
</script>

<style lang="scss">
.selected-item {
  //--selected-shadow-color: rgb(from var(--va-primary) r g b / 0.5) !important;
  //background-color: var(--va-background-secondary);
  //@apply shadow-[--selected-shadow-color] text-[var(--va-primary)];
  //box-shadow: 0 4px 8px var(--selected-shadow-color) !important;
  //border-radius: 10px;
  //padding: 8px 16px;
  @apply bg-white shadow-lg shadow-blue-500/50 text-blue-600 rounded-lg;
}

.non-selected-item {
  //background-color: transparent;
  //color: #5a5a5a;
  /* Ajusta este color según tu diseño */
  //cursor: pointer;
  //padding: 8px 16px;
  //transition: color 0.3s, background-color 0.3s;
  @apply bg-transparent text-gray-600 cursor-pointer hover:text-blue-500;
}

.non-selected-item:hover {
  color: var(--va-primary);
}

/* Estilo base para todas las pestañas */
.tab-item {
  /*display: inline-flex;
  align-items: center;
  padding: 3px 6px;
  border-radius: 8px;
  font-weight: bold;
  cursor: pointer;
  transition: all 0.3s ease;
  white-space: nowrap;
  margin-right: 4px;*/
  /* Espacio entre pestañas */
  @apply inline-flex items-center px-4 py-0 font-semibold transition-all duration-300 whitespace-nowrap mr-2 rounded-lg;
}

.tabtransition-move,
/* apply transition to moving elements */
.tabtransition-enter-active,
.tabtransition-leave-active {
  transition: all 0.3s ease;
}

.tabtransition-enter-from {
  opacity: 0;
  transform: translateX(200px) scaleX(2);
}

.tabtransition-enter-to {
  opacity: 1;
  transform: translateX(0px);
}

.tabtransition-leave-from {
  opacity: 1;
}

.tabtransition-leave-to {
  opacity: 0;
  transform: translateY(50px);
}

.tabtransition-leave-active {
  position: absolute;
}

.tabs-container {
  background: #f1f9fe;
  border-radius: 10px;
  //padding: 10px;
  //@apply bg-gray-100 rounded-lg p-2;
}
</style>
