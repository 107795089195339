import { defineStore } from 'pinia'

interface VisitedTab {
  path: string
  name: string
  saveAsTab: boolean
}

export const useGlobalStore = defineStore('global', {
  state: () => {
    return {
      isSidebarMinimized: false,

      tabs: [] as VisitedTab[],
      currentTab: null,
    }
  },

  actions: {
    toggleSidebar() {
      this.isSidebarMinimized = !this.isSidebarMinimized
    },
    addPathToTabs(tab: VisitedTab) {
      if (tab.saveAsTab && tab.path !== '/dashboard' && !this.tabs.find((t) => tab.path === t.path)) {
        this.tabs.push(tab)
      }
    },
  },
})
