import { RouteRecordRaw } from 'vue-router'

export default [
  {
    name: 'users',
    path: 'users',
    component: () => import('../../pages/users/UsersPage.vue'),
    meta: {
      title: 'Usuarios',
    },
  },
  {
    name: 'user-detail',
    path: 'user/:id',
    component: () => import('../../components/TabsView.vue'),
    redirect: { name: 'user-info' },
    props: {
      title: 'Detalles de Usuario',
      routes: [
        { name: 'user-info', label: 'Detalles' },
        { name: 'user-roles', label: 'Roles' },
      ],
    },
    meta: {
      title: 'Detalles de Usuario',
    },
    children: [
      {
        path: '',
        name: 'user-info',
        component: () => import('../../pages/users/widgets/EditUserForm.vue'),
        props: (route) => ({
          userId: route.params.id,
          isNew: false,
        }),
      },
      {
        path: 'roles',
        name: 'user-roles',
        component: () => import('../../pages/users/widgets/UserRoles.vue'),
        props: (route) => ({
          userId: route.params.id,
        }),
        meta: {
          title: 'Roles de Usuario',
        },
      },
    ],
  },
  {
    name: 'new-user',
    path: 'user/new',
    component: () => import('../../pages/users/widgets/EditUserForm.vue'),
    props: {
      userId: null,
      isNew: true,
    },
    meta: {
      title: 'Nuevo Usuario',
    },
  },
] as RouteRecordRaw[]
